import React from 'react';
import { Link } from 'react-router-dom';

import Logo from './svgs/Logo';
import { useUserContext } from '../context/user.js';

const Header = () => {
  const { isLoggedIn, isLoggingIn, user, logUserOut } = useUserContext();
  const { first_name } = user || {};

  return (
    <header className="h-28 flex items-center">
      <div className="flex items-center text-xl font-semibold text-gray-900">
        <Link to="/">
          <Logo size={172} />
        </Link>
      </div>

      <div className="ml-auto flex">
        {!isLoggedIn && !isLoggingIn && (
          <>
            <Link className="  text-primary px-6 py-2 rounded font-medium block" to="/login">
              Sign in
            </Link>
            <Link className="ml-2 bg-primary text-white px-6 py-2 rounded hidden lg:block" to="/signup">
              Get Started
            </Link>
          </>
        )}

        {isLoggedIn && !isLoggingIn && (
          <div className="flex items-center">
            <div className="whitespace-nowrap mr-4">Welcome, {first_name}! </div>
            <button onClick={logUserOut} className="w-full text-indigo-600  hover:bg-indigo-50  font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Logout
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
