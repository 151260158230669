import React from 'react';
import { Link } from 'react-router-dom';

import House from './svgs/House.js';
import { useUserContext } from '../context/user.js';

const InfoCard = () => {
  const { isLoggedIn, isLoggingIn } = useUserContext();
  return (
    <div className="bg-orange-50 rounded-md py-8 lg:py-20 px-8 flex items-center flex-col lg:flex-row">
      <div className="flex-1 lg:mr-12">
        <div className="text-4xl font-semibold">Your Trusted Rental Companion</div>
        <div className="text-2xl my-8 max-w-4xl mx-auto">
          Experience the Future of Renting with RentNation: Unlock the power of tenant reviews, gain valuable insights, and find your ideal rental home. Join RentNation today and embark on your journey towards
          hassle-free renting.
        </div>

        {!isLoggedIn && !isLoggingIn && (
          <Link className=" bg-primary text-white px-5 py-3 rounded text-center" to="/signup">
            Get Started With RentNation Today
          </Link>
        )}

        {isLoggedIn && !isLoggingIn && (
          <Link className=" bg-primary text-white px-5 py-3 rounded text-center" to="/dashboard">
            Go to Your Dashboard
          </Link>
        )}
      </div>

      <House size={520} className="hidden lg:block" />
    </div>
  );
};

export default InfoCard;
